import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMainContainer = styled.main`
  counter-reset: section;

  .breadcrumb {
    margin-bottom: 0px;
    font-weight: bold;
    text-align: left;
  }

  #header {
    font-weight: bold;
    text-align: center;
  }
`;

const BlankPage = () => (
  <StyledMainContainer className="fillHeight">
    <div className="row">
      <div className="col-2">
        <span className="breadcrumb">
          <span className="arrow">&larr; </span>
          <Link to="/extra">Back to Extras</Link>
        </span>
      </div>
      <div className="col-2">
        <h1 id="header">This page as been left intentionally blank.</h1>
      </div>
    </div>
  </StyledMainContainer>
);

BlankPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default BlankPage;
